.project-item-editorPage {
  height: 100vh;
  display: flex;
  flex-direction: column;

  .editor {
    display: flex;
    flex-direction: row;
    flex: 1 1;
    min-height: 180px;
    min-width: 950px;
  }

  .editor-sidebar {
    max-width: 350px;
    height: 100%;
    background-color: #ffffff;
    padding: 40px;
    overflow-y: auto;
  }

  .editor-sign-info {
    position: absolute;
    padding: 10px;
  }

  .editor-preview {
    flex: 1;
    position: relative;
  }

  .editor-preview-sign {
    position: absolute;
    left: 0;
    top: 0;
    bottom: 0;
    right: 0;
    display: flex;
    align-items: center;
    justify-content: center;
    background-color: #eee;
    flex-direction: column;
    gap: 0.1em;
  }

  .svg-container {
    max-width: 100%;
    max-height: 100%;
    svg {
      width: 80%;
      height: 100%;
    }
  }

  .editor-preview-actions {
    height: 10%;
  }

  .editor-actions {
    position: absolute;
    bottom: 0;
    right: 0;
  }

  .editor-preview-action-buttons {
    display: flex;
    justify-content: flex-end;
    padding: 40px;
  }

  .bold {
    font-weight: 700;
  }

  .action-button,
  .action-toggle {
    margin-right: 20px;
  }

  .action-toggle {
    font-weight: 700;
    display: inline-block;
    line-height: 1.5;
    padding: 0.375rem 0.75rem;
    font-size: 1rem;
    text-transform: uppercase;
    cursor: pointer;
    input,
    label {
      cursor: pointer;
    }
  }

  .instructions {
    display: block;
    font-size: 11px;
    margin-bottom: 5px;
  }

  svg {
    user-select: none;
  }
}
