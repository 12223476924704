.usersPage {
  .background-paper {
    background-color: #f3f3f3;
  }

  .content {
    margin-top: 50px;
  }

  .headerArea {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    margin: 20px 0 20px 0;
  }

  .spinner-center {
    min-height: 500px;
    display: flex;
    justify-content: center;
    align-items: center;
  }

  .table {
    background-color: #ffffff;
  }

  .icon {
    margin-left: 20px;
  }
}
