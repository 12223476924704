.editorPage {
  height: 100vh;
  display: flex;
  flex-direction: column;

  .editor {
    display: flex;
    flex-direction: row;
    flex: 1;
    min-height: 180px; /* IMPORTANT: you need this for non-chrome browsers */
    min-width: 950px;
  }

  .editor-sidebar {
    height: 100%;
    background-color: #ffffff;
    padding: 40px;
    overflow-y: auto;
  }

  .editor-preview {
    flex: 1;
    position: relative;
  }

  .editor-preview-sign {
    position: absolute;
    left: 0;
    top: 0;
    bottom: 0;
    right: 0;
    display: flex;
    align-items: center;
    justify-content: center;
    background-color: #eee;
    flex-direction: column;
    gap: 0.1em;
  }

  .editor-preview-actions {
    height: 10%;
  }

  .editor-actions {
    position: absolute;
    bottom: 0;
    right: 0;
  }

  .editor-preview-action-buttons {
    display: flex;
    justify-content: flex-end;
    padding: 40px;
  }

  .bold {
    font-weight: 700;
  }

  .action-price {
    color: #024086;
    margin-right: 20px;
    font-size: 24px;
    font-weight: bold;
  }

  .action-button {
    margin-right: 20px;
  }

  .mm {
    display: inline-block;
    position: relative;
  }

  .mm::after {
    position: absolute;
    top: 5px;
    right: 0.5em;
    transition: all 0.05s ease-in-out;
  }

  .mm:hover::after,
  .mm:focus-within::after {
    right: 1.5em;
  }

  @supports (-moz-appearance: none) {
    .mm::after {
      right: 1.5em;
    }
  }

  .mm::after {
    content: "mm";
  }

  .editor-export-overlay {
    position: absolute;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    background-color: rgba(255, 254, 255, 0.8);
    display: flex;
    align-items: center;
    justify-content: center;
    font-size: 2em;
    text-align: center;
    padding: 2em;
  }
}
