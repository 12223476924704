.splitted-sign-container {
  display: flex;
  position: relative;
  top: -20px;
}

.splitter-svg-component {
  width: 100%;
  height: 100%;
  outline: 2px dotted black;
}

.splitter-line {
  mix-blend-mode: difference;
  //stroke: #fff;
}

.splitter-text {
  mix-blend-mode: difference;
  fill: #fff;
  font-weight: 100;
}

// @keyframes svgerror {
//   0% {
//     fill-opacity: 1;
//   }
//   90% {
//     fill-opacity: 1;
//   }
//   95% {
//     fill-opacity: 0.8;
//   }
//   100% {
//     fill-opacity: 1;
//   }
// }

@keyframes svgerror_outline {
  0% {
    outline: 2px dotted black;
  }
  90% {
    outline: 2px solid red;
  }
  100% {
    outline: 2px dotted black;
  }
}

.svg-size-error {
  // path {
  //   animation: svgerror 2s infinite;
  // }
  animation: svgerror_outline 1s infinite;
}

.layout-column {
  display: flex;
  flex-direction: column;
  gap: 3px;
  justify-content: center;
}

.layout-row {
  display: flex;
  flex-direction: row;
  gap: 10px;
  justify-content: center;
}

.splitted-sign-title {
  padding: 0px 4px;
  display: inline-block;
  border-radius: 0;
  border-top-left-radius: 5px;
  border-top-right-radius: 5px;
  border: 1px solid black;
  border-bottom: 0;
  font-size: 11px;
  background-color: white;
}
