.templatesPage {
  .templates {
    margin-bottom: 16px;
  }

  .sign-image {
    height: 250px;
    width: 100%;
    background-color: #00beda;
    display: flex;
    flex-direction: column;
  }

  .template-actions {
    display: flex;
    justify-content: space-between;
  }

  .template-actions-icon {
    cursor: pointer;
  }

  .template-preview {
    flex: 1;
    padding: 20px;
    cursor: pointer;
  }

  .template-info {
    cursor: pointer;
  }

  .sign-image .svg-component {
    width: 100%;
  }

  .content {
    margin-top: 50px;
  }

  .headerArea {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    margin: 20px 0 20px 0;
  }

  .table {
    background-color: #ffffff;
  }
}
