.ordersPage {
  .background-paper {
    background-color: #f3f3f3;
    min-height: 1200px;
  }

  .content {
    margin-top: 50px;
  }

  .headerArea {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    margin: 20px 0 20px 0;
  }

  td svg {
    cursor: pointer;
  }

  .table {
    background-color: #ffffff;
  }

  .table-nested .td-white {
    border: 10px solid white;
    border-right: 0;
    border-left: 0;
    width: 2%;
  }

  .table-nested .td-icons {
    text-align: right;
    padding-right: 100px;
  }

  .table-nested td .icon {
    margin-left: 20px;
  }
}
