.loginPage {
  #loginBox {
    margin-top: 280px;
  }

  .loginLogo {
    margin: 50px;
    display: flex;
    justify-content: center;
    align-items: center;
    overflow: hidden;
  }

  .loginLogo img {
    width: 80%;
    height: 80%;
  }

  .loginHelp {
    margin-top: 10px;
    display: flex;
    justify-content: center;
    align-items: center;
    text-decoration: none;
  }
}
