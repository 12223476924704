a:link {
  text-decoration: none;
}

a:visited {
  text-decoration: none;
}

a:hover {
  text-decoration: none;
}

a:active {
  text-decoration: none;
}

h1 {
  color: #024086;
}

h3 {
  color: #024086;
}

.btn-kaskea {
  background-color: #024086;
  border-color: #024086;
}

.btn-kaskea-inverse {
  background-color: #ffffff;
  color: #024086;
  border-color: #024086;
}

.uppercase {
  text-transform: uppercase;
}
