.bg-kaskea {
  background-color: #ffffff;
}

.bg-kaskea-nav-helper {
  height: 50px;
  background-color: #024086;
  color: #ffffff;
}

ul {
  margin: 0;
  padding: 0;
  margin-bottom: 0 !important;
}

li {
  display: inline;
  padding: 16px 34px 14px 32px;
  cursor: pointer;
}

.bg-kaskea-nav-helper a {
  color: #ffffff;
  text-transform: uppercase;
}

.active {
  background-color: #132856;
}

.logout {
  color: #024086;
  float: right;
}